
<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />

</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_jmbfp',
      headers: [
        [{
          label: 'Nama Dosen Pembimbing Magang Kependidikan',
          dataKey: 'data_master_dosen.full_name'
        }, {
          label: 'Jumlah Mahasiswa Bimbingan',
          dataKey: 'jumlah_mahasiswa_bimbingan',
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Rata-Rata Banyaknya Pertemuan/ Mahasiswa/Semester',
          dataKey: 'rata_rata_banyaknya_pertemuan_mahasiswa_semester',
          dataAttributes: { class: 'text-center' },
        }]
      ],
      form: {
        inputs: [{
          label: 'Nama Dosen',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=all`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          rules: 'required',
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'Jumlah Mahasiswa Bimbingan',
          name: 'jumlah_mahasiswa_bimbingan',
          dataKey: 'jumlah_mahasiswa_bimbingan',
          type: 'number',
          
        }, {
          label: 'Rata-Rata Banyaknya Pertemuan/ Mahasiswa/Semester',
          name: 'rata_rata_banyaknya_pertemuan_mahasiswa_semester',
          dataKey: 'rata_rata_banyaknya_pertemuan_mahasiswa_semester',
          type: 'text',
          
        }]
      },
    }
  },
}
</script>
